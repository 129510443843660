if (document.querySelectorAll('[data-component="float"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const toggle = document.querySelector('[data-toggle="float"]');
        const target = document.querySelector('.float-group');
        
        toggle.addEventListener('click', function () {
            this.classList.toggle('show');
            target.classList.toggle('show');
        })
    })
}